// export const URL_TARGET = "https://apiconnective.site/api/";
// export const URL_TARGET_UPLOAD = "https://upload.apiconnective.site/";

// //MS URL
// export const URL_TARGET_MS_DEV = "https://api.dev.carii.pro/api/";
// export const URL_TARGET_MS_QA = "https://api.qa.carii.pro/api/";
// export const URL_TARGET_MS_LIVE = "https://api.carii.pro/api/";

// //MS URL v2
// export const V2 = 'v2/';

// //MS WS
// export const URL_TARGET_WS_DEV = 'wss://api.dev.carii.pro';
// export const URL_TARGET_WS_QA = 'wss://api.qa.carii.pro';
// export const URL_TARGET_WS_LIVE = 'wss://api.carii.pro';


// //token target url
// export const FIND_TOKEN = "client/access/findone";
// export const CREATE_TOKEN = "client/access";

// //get tennat
// export const TENANTS = "v2/tenants/";

// //Community target url
// export const COMMUNITY_EXTERNAL = "client/community";
// export const FINDALL_COMMUNITY_EXTERNAL = "client/community/findall";

// //Community member target url
// export const COMMUNITY_MEMBER_EXTERNAL = "client/member";
// export const FINDALL_COMMUNITY_MEMBER_EXTERNAL = "client/member/findall";
// export const LEFT_COMMUNITY_MEMBER_EXTERNAL = "client/member/left";

// //User target url
// export const USER_EXTERNAL = "client/user";
// export const FINDALL_USER_EXTERNAL = "client/user/findall";

// //ms url module user
// export const FINDONE_USER = "userProfiles/ms_users/findOne";
// export const FINDONE_USER_GLOBAL = "jobBoard/user-profileGet";
// export const UPDATE_USER = "jobBoard/user-profileEdit";


// //ms url module user
// export const FINDONE_USER_V2 = "v2/users/";


// export const LOGIN_USER = "users/login";

// export const REGISTER_USER = "users/registerV1";
// export const GET_OTP = "users/getOtp";
// export const REGISTER_SUBMIT_OTP_USER = "";
// export const REGISTER_PAID = "users/registerV2";

// //ms url module Job Board
// export const FIND_JOB_BOARD = "jobBoard/ms_jobs/find";
// export const FIND_JOB_BOARD_WITH_FILTER = "jobBoard/jobs-list";
// export const APPLY_JOB_BOARD = "jobBoard/jobs-apply";
// export const CREATE_JOB_BOARD = "jobBoard/jobs-create";
// export const UPDATE_JOB_BOARD = "jobBoard/jobs-edit";
// export const CREATE_EMPLOYER = "jobBoard/employer-create";
// export const EDIT_EMPLOYER = "jobBoard/employer-edit";
// export const ADD_CONVERSATION_USER = "jobBoard/jobs-conversationAdd";
// export const READ_CONVERSATION = "jobBoard/jobs-conversationUnreadClear";
// export const READ_CONVERSATION_ADMIN =
//     "jobBoard/jobs-conversationUnreadClearForAdmin";
// export const MOVED_EMPLOYE_STATUS = "jobBoard/jobs-moved";

// //ms url module exp builder
// export const FINDONE_COMPONENTITEMS = "component/ms_componentItems/findOne";
// export const FIND_DYNAMIC_COLLECTIONS = "component/dynamic/find";
// export const SAVE_EXP_BUILDER_DYNAMIC_SCHEMA =
//     "component/expBuilder-pageBuilderEdit";
// export const CREATE_COLLECTION_DYNAMIC =
//     "component/expBuilder-pageBuilderRefCollectionCreate";
// export const REMOVE_FIELD_EXP_BUILDER =
//     "component/expBuilder-pageBuilderElementRemove";
// export const ADD_FIELD_DYNAMIC_FORM_EXP_BUILDER =
//     "component/expBuilder-pageBuilderElementAdd";
// export const ADD_VALUE_DYNAMIC_COLLECTION =
//     "component/expBuilder-pageBuilderRefCollectionAdd";
// export const EDIT_VALUE_DYNAMIC_COLLECTION =
//     "component/expBuilder-pageBuilderRefCollectionEdit";
// export const MOVE_DYNAMIC_FIELD = "component/expBuilder-pageBuilderElementMove";

// export const FINDONE_COMPONENTITEMS_COMPONENT =
//     "component/ms_componentItems/findOne";
// export const FIND_DYNAMIC_COLLECTIONS_COMPONENT = "component/dynamic/find";
// export const SAVE_EXP_BUILDER_DYNAMIC_SCHEMA_COMPONENT =
//     "component/pageBuilderEdit";
// export const CREATE_COLLECTION_DYNAMIC_COMPONENT =
//     "component/pageBuilderRefCollectionCreate";
// export const REMOVE_FIELD_EXP_BUILDER_COMPONENT =
//     "component/pageBuilderElementRemove";
// export const ADD_FIELD_DYNAMIC_FORM_EXP_BUILDER_COMPONENT =
//     "component/pageBuilderElementAdd";
// export const ADD_VALUE_DYNAMIC_COLLECTION_COMPONENT =
//     "component/pageBuilderRefCollectionAdd";
// export const EDIT_VALUE_DYNAMIC_COLLECTION_COMPONENT =
//     "component/pageBuilderRefCollectionEdit";
// export const MOVE_DYNAMIC_FIELD_COMPONENT = "component/pageBuilderElementMove";


// //exp builder v2

// export const FINDONE_COMPONENTITEMS_COMPONENT_V2 = "v2/components/items/"
// export const SAVE_EXP_BUILDER_DYNAMIC_SCHEMA_COMPONENT_V2 = "v2/components/items/";
// export const FIND_DYNAMIC_COLLECTIONS_V2 = "v2/components/ref/";

// export const FIND_COMPONENTLIBRARY_V2 = "v2/components/library";
// export const DEPLOY_COMPONENTLIBRARY_TOLIVE = "v2/components/library/deploy";

// export const FIND_TEMPLATELIBRARY_V2 = "v2/components/template";
// export const FINDONE_TEMPLATELIBRARY_COMPONENT_V2 = "v2/components/template/"
// export const DEPLOY_TEMPLATELIBRARY_TOLIVE = "v2/components/template/deploy";


// export const FIND_DYNAMIC_COLLECTIONS_COMPONENT_V2  = "v2/components/ref/";

// export const FINDONE_COMPONENTITEMS_PAGES = "v2/components/page/"


// //media Liblary
// export const GET_MEDIA_LIBRARY =
//     "userMediaLibrary/ms_userMediaLibrary/findPagging";
// export const ADD_MEDIA_LIBRARY_IMAGE = "?type=images";
// export const ADD_MEDIA_LIBRARY_VIDEO = "?type=video";
// export const ADD_MEDIA_LIBRARY_FILE = "?type=file";
// export const SAVE_MEDIA_LIBRARY = "userMediaLibrary/ms_userMediaLibrary/insert";

// // membership
// export const GET_MEMBER_SHIP = "tenant/getMembershipTypeList";
// export const GET_STRIPE = "payment-key-list-bySite";

// //collection name reference
// export const COLLECTION_NAME_REF_LIST_EXPERTISE = "ref_list_expertise";
// export const COLLECTION_NAME_MS_EMPLOYERS = "ms_employers";
// export const COLLECTION_NAME_REF_LIST_JOB_TYPE = "ref_list_job_type";
// export const COLLECTION_NAME_MS_JOB_APPLICANT = "ms_jobsApplicant";
// export const COLLECTION_NAME_MS_JOB_APPLICANT_CONVERSATION =
//     "ms_jobsApplicantConversation";
// export const COLLECTION_NAME_MS_LIST_COLECTION = "ref_listCollection";
// export const COLLECTION_NAME_MS_USER_TYPE = "ms_userType";
// export const COLLECTION_NAME_MS_COMPONENT_ELEMENT = "ms_componentElement";
// export const COLLECTION_NAME_MS_TENANT = "carii_tenants";

// export const METEOR_SECRET_KEY = "v5.carii.pro";

// export const STRIPE_TOKEN_URL = "https://api.stripe.com/v1/tokens";

// export const FORGOT_PASSWORD_USER = "users/forgotPassword";
// export const RESET_PASSWORD_USER = "users/passwordResetByEmail";

// export const FORGOT_PASSWORD_USER_V2 = "v2/users/forgot-password";
// export const RESET_PASSWORD_USER_V2 = "v2/users/reset-password";

// // EXP BUILDER ASSET URL
// export const EXP_BUILDER_JS = "index.js";
// export const EXP_BUILDER_CSS = "index.css";
// export const EXP_BUILDER_JS_DEV =
//     "https://dev.mf.apiconnective.site/src/builder/builder.js";
// export const EXP_BUILDER_CSS_DEV =
//     "https://dev.mf.apiconnective.site/src/builder/builder.css";
// export const EXP_BUILDER_JS_QA =
//     "https://dev.mf.apiconnective.site/src/builder/builder.js";
// export const EXP_BUILDER_CSS_QA =
//     "https://dev.mf.apiconnective.site/src/builder/builder.css";
// export const EXP_BUILDER_JS_LIVE =
//     "https://dev.mf.apiconnective.site/src/builder/builder.js";
// export const EXP_BUILDER_CSS_LIVE =
//     "https://dev.mf.apiconnective.site/src/builder/builder.css";

// // ASSET
// export const ASSET_LOADING_IMAGE =
//     "https://carii-indo.c3.carii.com/assets/post/files/carii_4jgkywvstpgho2vck_icn_allien.svg";
// export const ASSET_BACKGROUND_IMAGE_DEFAULT =
//     "https://v31-qa.c3.carii.com/assets/post/images/carii_eaknksza54msvnxvc_carii_br3o8ushdm6tobznv_bg_auth__2_.jpg";

// export const ASSET_URL_TARGET_ICON =
//     "https://www.connective.network/packages/carii_theme/public/images/new_icon_version_v3_1/icon-files";
// export const ASSET_PDF_ICON =
//     "https://www.connective.network/packages/carii_theme/public/images/new_icon_version_v3_1/icon-files/file-pdf.jpg";
// export const ASSET_URL_TARGET_PLACEHOLDER = "https://via.placeholder.com";




// //Module newsletter
// export const NEWSLETTER = "newsletter";
// export const CREATE_NEWSLETTER = "newsletter/submit";
// export const NEWSLETTER_V2 = "v2/newsletter";

// //Module newsletter
// export const CONTACT = "contactus";
// export const CREATE_CONTACT = "contactus/submit";


// //module submit future now home

// export const APPLICANT = "v2/tenantform/application";
// export const SPONSORSHIP = "v2/tenantform/sponsorship";
// export const VOLUNTEER = "v2/tenantform/volunteer";
// export const SCHOLARSHIP = "v2/tenantform/scholarship";


// //KB
// export const FIND_ALL_KB = "knowledgebank";

// //public stream
// export const FIND_PUBLIC_STREAM = "news";


// // EXP BUILDER FORM ASSET URL
// export const URL_TARGET_EXP_BUILDER_FORM_CSS_DEV =
//     'https://dev.mf.apiconnective.site/src/builder-form/builder-form.css'
// export const URL_TARGET_EXP_BUILDER_FORM_JS_DEV =
//     'https://dev.mf.apiconnective.site/src/builder-form/builder-form.js'
// export const URL_TARGET_EXP_BUILDER_FORM_CSS_QA =
//     'https://dev.mf.apiconnective.site/src/builder-form/builder-form.css'
// export const URL_TARGET_EXP_BUILDER_FORM_JS_QA =
//     'https://dev.mf.apiconnective.site/src/builder-form/builder-form.js'
// export const URL_TARGET_EXP_BUILDER_FORM_CSS_LIVE =
//     'https://dev.mf.apiconnective.site/src/builder-form/builder-form.css'
// export const URL_TARGET_EXP_BUILDER_FORM_JS_LIVE =
//     'https://dev.mf.apiconnective.site/src/builder-form/builder-form.js'


// //Tenant
// export const TENANT = "v2/tenants/";
// export const TENANT_LIST = "v2/tenants/simple";
// export const TENANT_ASSET_CONFIG = "v2/components/tenant/assetconfig/";

//PUNYA GUE 
export const GETPERSONALARTS = "https://settings.reviz-project.com/getPerArts"
export const GETCOMARTS = "https://settings.reviz-project.com/getComArts"
export const GETICONBANNER = "https://settings.reviz-project.com/getIconBanner"
export const GETPRICING = "https://settings.reviz-project.com/getPricing"
export const GETVTUBERMODEL = "https://settings.reviz-project.com/getVtModel"
export const GETEVENT = "https://settings.reviz-project.com/getEvent"
export const GETBACKGROUND = "https://settings.reviz-project.com/getProperties"
export const GETANIMATION = "https://settings.reviz-project.com/getAnimation"
export const GETQUEUE = "https://settings.reviz-project.com/getQueue"
export const GETOVERLAY = "https://settings.reviz-project.com/getOverlay"
