import vtuberAPI from '../vtuber';

export const FindAll = async () => {
  try {

    let vtuber = new vtuberAPI();
    let getVtuber = await vtuber.GetALL();
    let vtuberData = getVtuber["data"];

    if (vtuberData) {
      return vtuberData;
    }

    return null;
  } catch (e) {
    return null;
  }
}


// export const CreateToken = async (paramCard, publickey) => {
//   try {

//     let membership = new membershipApi();
//     let getMembership = await membership.createToken(paramCard, "create", publickey);
//     let membershipData = getMembership;

//     if (membershipData) {
//       return membershipData;
//     }

//     return null;
//   } catch (e) {
//     return null;
//   }
// }

