import Profile from './Profile';
import Header from './../../Header';

function ProfileRoutes() {
    return(
<>
<Header />
<Profile />
</>
    )
}
export default ProfileRoutes;