import logo_golden from './../../Img/Golden Gif Waving.gif'
import { Row, Col, Container, Button } from 'react-bootstrap';
import Slide from './../../Slide_over/Slide_over.js'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { XIcon } from '@heroicons/react/outline'
import * as event_act from '../../../Middleware/action/event.action';
import parse from 'html-react-parser';

function Profile() {
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch();
    const event = useSelector((state) => (state.event));
    useEffect(() => {
        dispatch(event_act.GetAll())
    }, [])
    useEffect(() => {
        console.log("event", event)
    }, [event])

    function txt() {
        return ` Hello! My name is Golden Constellatia. But you can call me Golden, Constellatia, Stella or Tia :3 <br /><br />

        Im a Doctor, Drawing artist, Rigger and also a Cosplayer （⌒▽⌒）. I love to draw since i was a kid.
        <br /><br />The first time i open a commission, and know that my client loves my work, it feels so wonderful! I really love to make other people happy with my artworks!  Then, i continue to open for commission, and i always learned something new from them!! (^▽^)<br /><br />

        Oh i also love to make my own Cosplay costumes and Cosplay properties too!! Crafting is sooo much fun but its quite hard to do sometimes hahah `
    }

    return (
        <>
            <div className="flex flex-row h_inherit ">
                <div className="basis-2/12 side_img"></div>
                <div className="basis-full content_c animate-flip-down">
                    <div >
                        <img className="img_golden" src={logo_golden} alt="Golden" />
                        <p className="antialiased txt_size text-center pt-5 mbl_cons">
                            {/* {event && event.data
                                ? parse(event && event.data && event.data[0].bio)
                                : parse(txt())
                            } */}
                            {parse(txt())}
                        </p>
                    </div>
                    <div className="center_obj">
                        <button className="Flying_upload inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 animate-bounce" onClick={() => setOpen(true)}>COMMISSION </button>
                    </div>
                </div>
                <div className="basis-2/12 side_img"></div>
            </div>
            <Slide Isopen={open}>
                <button type="button" className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={() => setOpen(false)}>
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </Slide>
        </>
    );
}

export default Profile;