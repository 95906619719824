// import Hg1 from "./../../../../Img/Arts/Commish/Furry- Monster Commission/Hg1.webp"
// import Hg2 from "./../../../../Img/Arts/Commish/Furry- Monster Commission/Hg2.jpg"

// import Commish_1 from "./../../../../Img/Arts/Commish/Chibi Commission/Commish_1.jpg"
// import Commish_2 from "./../../../../Img/Arts/Commish/Chibi Commission/Commish_2.png"

// import Commish_3 from "./../../../../Img/Arts/Commish/Furry- Monster Commission/Commish_3.jpg"
// import Commish_4 from "./../../../../Img/Arts/Commish/Furry- Monster Commission/Commish_4.jpg"
// import Commish_5 from "./../../../../Img/Arts/Commish/Furry- Monster Commission/Commish_5.jpg"
// import Commish_6 from "./../../../../Img/Arts/Commish/Furry- Monster Commission/Commish_6.jpg"
// import Commish_7 from "./../../../../Img/Arts/Commish/Furry- Monster Commission/Commish_7.jpg"
// import Commish_8 from "./../../../../Img/Arts/Commish/GIF Commission/Commish_8.gif"
// import Commish_9 from "./../../../../Img/Arts/Commish/Human Commission/Commish_9.jpg"
// import Commish_10 from "./../../../../Img/Arts/Commish/Human Commission/Commish_10.jpg"
// import Commish_11 from "./../../../../Img/Arts/Commish/Human Commission/Commish_11.jpg"
// import Commish_12 from "./../../../../Img/Arts/Commish/Human Commission/Commish_12.jpg"
// import Commish_13 from "./../../../../Img/Arts/Commish/Human Commission/Commish_13.jpg"
// import Commish_14 from "./../../../../Img/Arts/Commish/Human Commission/Commish_14.jpg"
// import Commish_15 from "./../../../../Img/Arts/Commish/Human Commission/Commish_15.jpg"
// import Commish_16 from "./../../../../Img/Arts/Commish/Human Commission/Commish_16.jpg"
// import Commish_17 from "./../../../../Img/Arts/Commish/Human Commission/Commish_17.jpg"
// import Commish_18 from "./../../../../Img/Arts/Commish/Human Commission/Commish_18.jpg"
// import Commish_19 from "./../../../../Img/Arts/Commish/Human Commission/Commish_19.jpg"
// import Commish_20 from "./../../../../Img/Arts/Commish/Human Commission/Commish_20.jpg"
// import Commish_21 from "./../../../../Img/Arts/Commish/Human Commission/Commish_21.jpg"
// import Commish_22 from "./../../../../Img/Arts/Commish/Human Commission/Commish_22.jpg"
// import Commish_23 from "./../../../../Img/Arts/Commish/Human Commission/Commish_23.jpg"
// import Commish_24 from "./../../../../Img/Arts/Commish/Human Commission/Commish_24.jpg"
// import Commish_25 from "./../../../../Img/Arts/Commish/Human Commission/Commish_25.jpg"
// import Commish_26 from "./../../../../Img/Arts/Commish/Human Commission/Commish_26.jpg"
// import Commish_27 from "./../../../../Img/Arts/Commish/Human Commission/Commish_27.jpg"
// import Commish_28 from "./../../../../Img/Arts/Commish/Human Commission/Commish_28.jpg"
// import Commish_29 from "./../../../../Img/Arts/Commish/Human Commission/Commish_29.jpg"
// import Commish_30 from "./../../../../Img/Arts/Commish/Human Commission/Commish_30.jpg"
// import Commish_31 from "./../../../../Img/Arts/Commish/Human Commission/Commish_31.jpg"
// import Commish_32 from "./../../../../Img/Arts/Commish/Human Commission/Commish_32.jpg"
// import Commish_33 from "./../../../../Img/Arts/Commish/Human Commission/Commish_33.jpg"
// import Commish_34 from "./../../../../Img/Arts/Commish/Human Commission/Commish_34.jpg"
// import Commish_35 from "./../../../../Img/Arts/Commish/Human Commission/Commish_35.jpg"
// import Commish_36 from "./../../../../Img/Arts/Commish/Human Commission/Commish_36.jpg"
// import Commish_37 from "./../../../../Img/Arts/Commish/Human Commission/Commish_37.jpg"
// import Commish_38 from "./../../../../Img/Arts/Commish/Human Commission/Commish_38.jpg"

import { useSelector, useDispatch } from 'react-redux';
import * as artsCom_act from '../../../../../Middleware/action/arts.action';
import React, { useEffect, useState,useCallback } from 'react';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

// const callouts = [
//     {
//         name: 'My Arts 1',
//         description: 'Commish',
//         imageSrc: Hg1,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 2',
//         description: 'Commish',
//         imageSrc: Hg2,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//     {
//         name: 'My Arts 3',
//         description: 'Commish',
//         imageSrc: Commish_1,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_2,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_3,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 1',
//         description: 'Commish',
//         imageSrc: Commish_4,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 2',
//         description: 'Commish',
//         imageSrc: Commish_5,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//     {
//         name: 'My Arts 3',
//         description: 'Commish',
//         imageSrc: Commish_6,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_7,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_8,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 1',
//         description: 'Commish',
//         imageSrc: Commish_9,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 2',
//         description: 'Commish',
//         imageSrc: Commish_10,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//     {
//         name: 'My Arts 3',
//         description: 'Commish',
//         imageSrc: Commish_11,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_12,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_13,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 1',
//         description: 'Commish',
//         imageSrc: Commish_14,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 2',
//         description: 'Commish',
//         imageSrc: Commish_15,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//     {
//         name: 'My Arts 3',
//         description: 'Commish',
//         imageSrc: Commish_16,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_17,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_18,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 1',
//         description: 'Commish',
//         imageSrc: Commish_19,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 2',
//         description: 'Commish',
//         imageSrc: Commish_20,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//     {
//         name: 'My Arts 3',
//         description: 'Commish',
//         imageSrc: Commish_21,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_22,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_23,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 1',
//         description: 'Commish',
//         imageSrc: Commish_24,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 2',
//         description: 'Commish',
//         imageSrc: Commish_25,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//     {
//         name: 'My Arts 3',
//         description: 'Commish',
//         imageSrc: Commish_26,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_27,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_28,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 1',
//         description: 'Commish',
//         imageSrc: Commish_29,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 2',
//         description: 'Commish',
//         imageSrc: Commish_30,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//     {
//         name: 'My Arts 3',
//         description: 'Commish',
//         imageSrc: Commish_31,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_32,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_33,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 1',
//         description: 'Commish',
//         imageSrc: Commish_34,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 2',
//         description: 'Commish',
//         imageSrc: Commish_35,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//     {
//         name: 'My Arts 3',
//         description: 'Commish',
//         imageSrc: Commish_36,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_37,
//         imageAlt: 'Commish',
//         href: '#',
//       },
//       {
//         name: 'My Arts 4',
//         description: 'Commish',
//         imageSrc: Commish_38,
//         imageAlt: 'Commish',
//         href: '#',
//       },

  
//   ]
  
  export default function Commish() {
    const dispatch = useDispatch();
    const arts = useSelector((state) => (state.arts));
    useEffect(() => {
      dispatch(artsCom_act.GetAllCommisionArts())
    }, [])
//     useEffect(() => {
//  console.log(arts)
//     }, [arts])
const [currentImage, setCurrentImage] = useState(0);
const [viewerIsOpen, setViewerIsOpen] = useState(false);

const openLightbox = useCallback((event, { photo, index }) => {
  setCurrentImage(index);
  setViewerIsOpen(true);
}, []);

const closeLightbox = () => {
  setCurrentImage(0);
  setViewerIsOpen(false);
};
    return (
      <div className="">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
            <h2 className="text-2xl font-extrabold text-gray-900">Collections</h2>
  
            <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-2">
              {arts.data?.map((callout) => (
                <div key={callout.arts_name} className="group relative pb-3">
                  <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 ">
                    <img
                      src={callout.path}
                      alt={callout.arts_name}
                      className="w-full h-full object-center object-cover"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    <a href={'#'}>
                      <span className="absolute inset-0" />
                      {callout.arts_name}
                    </a>
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  