import Header from "../../../../Header";
import Rigging from "./Rigging";
function RiggingRoutes() {
    return(
        <>
        <Header />
        <Rigging />
        </>

    )
}
export default RiggingRoutes;