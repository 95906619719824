/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { getYoutubeId } from '../../../../../Middleware/helpers/global'
import { useSelector, useDispatch } from 'react-redux';
import * as vtuber_act from '../../../../../Middleware/action/vtuber.action';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
function Video({ links }) {
  const href = 'https://www.youtube.com/embed/' + links
  return (
    <>
      <main style={{ backgroundColor : "white" }} className=" rounded-b-2xl">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Replace with your content */}
          <div className="px-4 py-6 sm:px-0">
            <div className="border-4 border-dashed border-gray-200 rounded-lg " >
              <iframe src={href}
                frameBorder='0'
                allow='autoplay; encrypted-media'
                allowFullScreen
                title='video'
                className="w-full h-full aspect-video"
              />
            </div>
          </div>
          {/* /End replace */}
        </div>
      </main>
    </>
  )
}
function Rigging() {
  const [Option, setOption] = useState("");
  const [Linkss, setLink] = useState("");
  console.log(Option, Linkss)
  function changes(Code_name, link) {
    setOption(Code_name);
    setLink(link)
  }

  const dispatch = useDispatch();
  const vtuber = useSelector((state) => (state.vtuber));
  useEffect(() => {
    dispatch(vtuber_act.GetAll())
  }, [])

  useEffect(() => {
    vtuber.data?.map((callout, index) =>{
      if (index === 0) {
        setOption(callout.code_name);
        setLink(getYoutubeId(callout.link_yt))        
      }
    })
  }, [vtuber])
  
  return (
    <div className="md:container md:mx-auto mt-4 animate-fade-right" style={{ zIndex : 9 }}>
      <header className="bg-white shadow rounded-t-2xl">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 text_banner">
          <div className='text-center title_cons mb-3'>Rigging</div>
          Code_Name
          <Menu as="div" className="relative inline-block text-left ml-5">
            <div>
              <Menu.Button className="text_banner inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                {Option}
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>            
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1" style={{ height: "300px", overflow: "auto" }}>
                  {vtuber.data?.map((callout, index) => (
                    <Menu.Item key={index} >
                      {({ active }) => (
                        <a
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                          onClick={() => changes(callout.code_name, getYoutubeId(callout.link_yt))}
                        >
                          {callout.code_name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>      
          <br/>  
            <p>
              Uppermost: The Newest
              <br/>
              Lowermost: The Oldest
            </p>

        </div>
      </header>
      <Video links={Linkss} />
    </div>
  )
}
export default Rigging;