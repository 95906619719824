// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDmgRGlJnLAK8_7FPJ8oQmhy-CyGB_fEBY",
  authDomain: "constellatia-dc3fd.firebaseapp.com",
  projectId: "constellatia-dc3fd",
  storageBucket: "constellatia-dc3fd.appspot.com",
  messagingSenderId: "983191201677",
  appId: "1:983191201677:web:fdc74fd9356858b2317fc4"
};
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  export const auth= getAuth(app);