/* eslint-disable no-mixed-operators */
/* This example requires Tailwind CSS v2.0+ */
import golden from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Golden Banner.png"
import zion from "./../../../../Img/Arts/Commish/Banner and Logo Commission/zion.png"
import kenna from "./../../../../Img/Arts/Commish/Banner and Logo Commission/kenna.png"
import { useSelector, useDispatch } from 'react-redux';
import * as logoBanner_act from '../../../../../Middleware/action/logobanner.action';
import React, { useEffect, useState } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

function BaneConAnim() {

  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(true);
  const logoBanner = useSelector((state) => (state.logobanner.data));
  useEffect(() => {
    dispatch(logoBanner_act.GetAll())
  }, [])
  useEffect(() => {
    if (logoBanner.filter(a => (a.animated === true || a.animated === "true")).length > 0) {
      setLoading(false)
    }
  }, [logoBanner])
  return (
    <div className="relative bg-white overflow-hidden mt-10  p-10 animate-fade-right">
      <div className="flex flex-col ...">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1 style={{ fontSize: "35px" }}>Logo Banner Animated</h1>
        </div>
        {Loading
          ? <><h1 className='animate-pulse'>LOADING...</h1><br></br>
            <ResponsiveMasonry
              // columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
              columnsCount={3} gutter="10px"
            >
              <Masonry>
                <img
                  src={golden}
                  style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
                  className='animate-pulse '
                  alt=""
                />
                <img
                  src={zion}
                  style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
                  className='animate-pulse '
                  alt=""
                />
                <img
                  src={kenna}
                  style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
                  className='animate-pulse '
                  alt=""
                />
              </Masonry>
            </ResponsiveMasonry>
          </>
          :
          <ResponsiveMasonry>
            <Masonry>
              {logoBanner.filter(a => (a.animated === true || a.animated === "true")).map((image, i) => (
                <img
                  key={i}
                  src={image.path}
                  style={{ width: "100%", display: "block" }}
                  alt=""
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        }
      </div>
    </div>
  )
}
export default BaneConAnim;