import { FindAll } from '../services/vtuber.service';
import * as type from '../type';

// export function GetMemberShip(tenantid){
//   return dispatch => {
//     FindOne(tenantid).then(data => {
//       dispatch({
//         type :FINDONE_MEMBRSHIP,
//         data : data
//       });
//     });
//   }
// }

// export function GetStripe(tenantid){
//   return dispatch => {
//     FindOneStripe(tenantid).then(data => {
//       dispatch({
//         type :FINDONE_STRIPE,
//         data : data
//       });
//     });
//   }
// }

export function GetAll() {
  return dispatch => {
    FindAll().then(data => {
      dispatch({
        type: type.LIST_VTUBERMODEL,
        data: data
      });
    });
  }
}




// export async function CreateTokenAction(paramCard, publickey) {
//   let result = await CreateToken(paramCard, publickey);
//   return result;
// }


