import axios from "axios";

// let cookieToken = require("./helpers/generateToken");
class Api {
    constructor() {
        this.header = "";
        this.auth = "";
        //uncoment jika pakai auth
        // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        // axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
        // axios.defaults.headers.get['Content-Type'] = 'Content-Type": "application/json"';
        // axios.defaults.headers.get['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept"';
    }
    setupHeader(AUTH_TOKEN = "") {
        // axios.defaults.headers.common["Authorization"] = "Bearer " + AUTH_TOKEN;
        return this;
    }
    // static setupCookie(userId, tenantId) {
    //     let token = cookieToken(userId, tenantId);
    //     axios.defaults.headers.common["x-apps-setup"] = token;
    //     return this;
    // }
    async getApi(url = "", data = {}) {
        //sample
        return await axios
            .get(url, {
                params: data,
            })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async getAll(url = "") {
        //sample
        return await axios
            .get(url, 
            //     {
            //     headers: {
            //         // Overwrite Axios's automatically set Content-Type
            //         "Content-Type": "application/json",
            //         // 'Access-Control-Allow-Origin': '*'
            //     },
            // }
            )
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // async postAPiFile(url, dataParams) {
    //     var bodyFormData = new FormData();
    //     //let nameVideo = name + '.mp4';
    //     //  bodyFormData.append('file_info', nameVideo);
    //     bodyFormData.append("file", dataParams);
    //     const res = await axios({
    //         method: "post",
    //         url: url,
    //         data: bodyFormData,
    //         headers: { "Content-Type": "multipart/form-data" },
    //     })
    //         .then(function (response) {
    //             //handle success
    //             // console.log("form data upload vod");
    //             // console.log(response);
    //             return response;
    //         })
    //         .catch(function (response) {
    //             //handle error
    //         });
    //     return res.data;
    // }

    // async postApi(url = "", data = {}) {
    //     let json = JSON.stringify(data);
    //     let res = await axios.post(url, json, {
    //         headers: {
    //             // Overwrite Axios's automatically set Content-Type
    //             "Content-Type": "application/json",
    //         },
    //     });

    //     return res.data;
    // }
    // async deletApi(url, data = {}) {
    //     let json = JSON.stringify(data);
    //     console.log(json);
    //     let res = await axios.delete(url, {
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: data,
    //     });
    //     return res.data;
    // }
    // async putApi(url = "", data = {}) {
    //     let json = JSON.stringify(data);
    //     let res = await axios.put(url, json, {
    //         headers: {
    //             // Overwrite Axios's automatically set Content-Type
    //             "Content-Type": "application/json",
    //         },
    //     });
    //     return res.data;
    // }
}

export default Api;
