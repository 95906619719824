import React, { Component } from 'react';
import Compressor from 'compressorjs';
// import Model_1 from './../Img/Vtub_fullbody/Model_1.png'
import Carousel from 'react-gallery-carousel';
export default class MultipleImageUploadComponent extends Component {

    fileObj = [];
    fileArray = [];
    fileblob = [];

    constructor(props) {
        super(props)
        this.state = {
            file: [null]
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
    }

    uploadMultipleFiles(e) {
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
        }
        this.setState({ file: this.fileArray })
    }

    uploadFiles(e) {
        e.preventDefault()
        let image = this.state.file;
        // console.log("a",image.length)
        for (let index = 0; index < image.length; index++) {
          this.fileblob.push(image[index])
        }
        const images_model = this.fileblob.map((imgs) => ({
          src: `${imgs}`
        }))   
        
          new Compressor(images_model, {
                quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                success: (res) => {
                  // compressedResult has the compressed file.
                  // Use the compressed file to upload the images to your server.        
                  console.log(res)
                },
              });    
    }
    
    render() {
        return (
            <form>
                <div className="form-group multi-preview">
                  <div class="grid grid-cols-4 gap-4">
                    {(this.fileArray || []).map(url => (
                    <div> <img class="w-full aspect-square ..." src={url} /></div>
                    ))}
                    </div>
                </div>
                        
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <span>Upload a file</span>
                            <input id="file-upload" name="file-upload" multiple type="file" onChange={this.uploadMultipleFiles} className="sr-only" />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                      </div>
                    </div>       
                {/* <div className="form-group">
                    <input type="file" className="form-control" onChange={this.uploadMultipleFiles} multiple />
                </div> */}
                <button type="button" className="btn_upload mt-3" onClick={this.uploadFiles}>Upload</button>
            </form >
        )
    }
}