import Hg5 from "./../../../../Img/Arts/My_arts/Hg5.jpg"
import Hg2 from "./../../../../Img/Arts/Commish/Furry- Monster Commission/Hg2.jpg"
import Modal_large from "../../../../Modal.js/Modal_large";
import { Fragment,useState,useRef } from 'react'
import { XIcon } from '@heroicons/react/outline'
import MyArts from "./Myarts";
import Commish from "./Commish";
const callouts = [
    {
      name: 'My Arts',
      description: 'Personal Arts',
      imageSrc: Hg5,
      imageAlt: 'Zhongli',
      href: '#',
      num : true
    },
    {
      name: 'Commision',
      description: 'Commission Arts',
      imageSrc: Hg2,
      imageAlt: 'Commission',
      href: '#',
      num : false
    },
  ]

  export default function Collection_commish() {
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    return (
      <>
      <div className="">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
                  <h2 className="text-2xl font-extrabold text-gray-900">Collections</h2>

                  <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-2">
                      {callouts.map((callout) => (
                      <div key={callout.name} className="group relative">
                          <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 ">
                              {/* sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1 */}
                              <img src={callout.imageSrc} alt={callout.imageAlt} className="w-full h-full object-center object-cover" />
                          </div>
                          <h3 className="mt-6 text-sm text-gray-500">
                              <a href={callout.href}>
                                  {callout.num
                                  ? <span className="absolute inset-0" onClick={()=> setOpen1(true)} />
                                      : <span className="absolute inset-0" onClick={()=> setOpen2(true)} />}
                                          {callout.name}
                              </a>
                          </h3>
                          <p className="text-base font-semibold text-gray-900">{callout.description}</p>
                      </div>
                      ))}
                  </div>
              </div>
          </div>
      </div>
      <Modal_large Isopen={open1} contents={<MyArts />}>
      <button type="button" className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8" onClick={()=> setOpen1(false)}
          >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      </Modal_large>
      <Modal_large Isopen={open2} contents={<Commish />}>
      <button type="button" className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8" onClick={()=> setOpen2(false)}
          >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      </Modal_large>
      </>
    )
  }
  