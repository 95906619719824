/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import * as event_act from '../../Middleware/action/event.action';
import parse from 'html-react-parser';

// import { XIcon } from '@heroicons/react/outline'

function Slide({ Isopen, children }) {
    // console.log("open",Isopen)
    const dispatch = useDispatch();
    const event = useSelector((state) => (state.event));
    useEffect(() => {
        dispatch(event_act.GetAll())
    }, [])
    useEffect(() => {
        console.log("event", event)
    }, [event])
    const [open, setOpen] = useState(Isopen)

    return (
        <>
            {Isopen && (
                <Transition.Root show={Isopen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 overflow-hidden" style={{ zIndex : 99 }} onClose={setOpen}>
                        <div className="absolute inset-0 overflow-hidden">
                            <Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>
                            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                                <Transition.Child as={Fragment} enter="transform transition ease-in-out duration-500 sm:duration-700" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leaveFrom="translate-x-0" leaveTo="translate-x-full">
                                    <div className="relative w-screen max-w-md">
                                        <Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
                                            <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                                                {children}
                                            </div>
                                        </Transition.Child>
                                        <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                                            <div className="px-4 sm:px-6">
                                                <Dialog.Title className="text-lg font-medium text-gray-900">COMMISSION</Dialog.Title>
                                            </div>
                                            <div className="mt-6 relative flex-1 px-4 sm:px-6">
                                                <p className="antialiased txt_size text-center">
                                                    {event && event.data
                                                        ? parse(event.data[0].description)
                                                        : "Not Loaded"}

                                                </p>
                                                {/* <div className="absolute inset-0 px-4 sm:px-6">
                                                    <div className="h-full border-2 border-dashed border-gray-200" aria-hidden="true" />
                                                </div> */}
                                                {/* /End replace */}
                                            </div>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            )}


        </>
    )
}
export default Slide;