import Pricing from './Pricing';
import Header from './../../Header';

function PricingRoutes() {
    return(
<>
<Header />
<Pricing />
</>
    )
}
export default PricingRoutes;