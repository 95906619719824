import Header from "../../../../Header";
import BaneCon from "./BaneCon"
function BaneConRoutes() {
    return(
        <>
        <Header />
        <BaneCon/>
        
        </>
    )
}
export default BaneConRoutes;