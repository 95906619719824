// import Hg5 from "./../../../../Img/Arts/My_arts/Hg5.jpg"
// import Hg3 from "./../../../../Img/Arts/My_arts/Hg3.jpg"
// import Hg4 from "./../../../../Img/Arts/My_arts/Hg4.jpeg"

// import Myarts_1 from "./../../../../Img/Arts/My_arts/Myarts_1.jpg"
// import Myarts_2 from "./../../../../Img/Arts/My_arts/Myarts_2.jpg"
// import Myarts_3 from "./../../../../Img/Arts/My_arts/Myarts_3.jpg"
// import Myarts_4 from "./../../../../Img/Arts/My_arts/Myarts_4.jpg"

// import Myarts_5 from "./../../../../Img/Arts/My_arts/Myarts_5.jpg"
// import Myarts_6 from "./../../../../Img/Arts/My_arts/Myarts_6.jpg"
// import Myarts_7 from "./../../../../Img/Arts/My_arts/Myarts_7.jpg"
// import Myarts_8 from "./../../../../Img/Arts/My_arts/Myarts_8.jpg"
// import Myarts_9 from "./../../../../Img/Arts/My_arts/Myarts_9.jpg"

// import Myarts_10 from "./../../../../Img/Arts/My_arts/Myarts_10.jpg"
// import Myarts_11 from "./../../../../Img/Arts/My_arts/Myarts_11.jpg"
// import Myarts_12 from "./../../../../Img/Arts/My_arts/Myarts_12.jpg"
// import Myarts_13 from "./../../../../Img/Arts/My_arts/Myarts_13.jpg"
// import Myarts_14 from "./../../../../Img/Arts/My_arts/Myarts_14.jpg"

// import Myarts_15 from "./../../../../Img/Arts/My_arts/Myarts_15.jpg"
// import Myarts_16 from "./../../../../Img/Arts/My_arts/Myarts_16.jpg"
// import Myarts_17 from "./../../../../Img/Arts/My_arts/Myarts_17.jpg"
// import Myarts_18 from "./../../../../Img/Arts/My_arts/Myarts_18.jpg"
// import Myarts_19 from "./../../../../Img/Arts/My_arts/Myarts_19.jpg"

// import Myarts_20 from "./../../../../Img/Arts/My_arts/Myarts_20.jpg"
// import Myarts_21 from "./../../../../Img/Arts/My_arts/Myarts_21.jpg"
// import Myarts_22 from "./../../../../Img/Arts/My_arts/Myarts_22.jpg"

import { useSelector, useDispatch } from 'react-redux';
import * as artsCom_act from '../../../../../Middleware/action/arts.action';
import React, { useEffect, useState } from 'react';

// const callouts = [
//     {
//         name: 'My Arts 1',
//         description: 'Personal Arts',
//         imageSrc: Hg3,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 2',
//         description: 'Personal Arts',
//         imageSrc: Hg4,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//     {
//       name: 'My Arts 3',
//       description: 'Personal Arts',
//       imageSrc: Hg5,
//       imageAlt: 'Myarts',
//       href: '#',
//     },
//     {
//         name: 'My Arts 4',
//         description: 'Personal Arts',
//         imageSrc: Myarts_1,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 5',
//         description: 'Personal Arts',
//         imageSrc: Myarts_2,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 6',
//         description: 'Personal Arts',
//         imageSrc: Myarts_3,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 7',
//         description: 'Personal Arts',
//         imageSrc: Myarts_4,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 8',
//         description: 'Personal Arts',
//         imageSrc: Myarts_5,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
      
//     {
//         name: 'My Arts 9',
//         description: 'Personal Arts',
//         imageSrc: Myarts_6,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 10',
//         description: 'Personal Arts',
//         imageSrc: Myarts_7,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 11',
//         description: 'Personal Arts',
//         imageSrc: Myarts_8,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 12',
//         description: 'Personal Arts',
//         imageSrc: Myarts_9,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 13',
//         description: 'Personal Arts',
//         imageSrc: Myarts_10,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
      
//     {
//         name: 'My Arts 1',
//         description: 'Personal Arts',
//         imageSrc: Myarts_11,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 1',
//         description: 'Personal Arts',
//         imageSrc: Myarts_12,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 15',
//         description: 'Personal Arts',
//         imageSrc: Myarts_13,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 16',
//         description: 'Personal Arts',
//         imageSrc: Myarts_14,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 17',
//         description: 'Personal Arts',
//         imageSrc: Myarts_15,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
      
//     {
//         name: 'My Arts 18',
//         description: 'Personal Arts',
//         imageSrc: Myarts_16,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 19',
//         description: 'Personal Arts',
//         imageSrc: Myarts_17,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 20',
//         description: 'Personal Arts',
//         imageSrc: Myarts_18,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 21',
//         description: 'Personal Arts',
//         imageSrc: Myarts_19,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 22',
//         description: 'Personal Arts',
//         imageSrc: Myarts_20,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 23',
//         description: 'Personal Arts',
//         imageSrc: Myarts_21,
//         imageAlt: 'Myarts',
//         href: '#',
//       },
//       {
//         name: 'My Arts 24',
//         description: 'Personal Arts',
//         imageSrc: Myarts_22,
//         imageAlt: 'Myarts',
//         href: '#',
//       }

//   ]
  
  export default function MyArts() {
    const dispatch = useDispatch();
    const arts = useSelector((state) => (state.arts));
    useEffect(() => {
      dispatch(artsCom_act.GetAllPersonalArts())
    }, [])
    useEffect(() => {
 console.log(arts)
    }, [arts])
    return (
      <div className="">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
            <h2 className="text-2xl font-extrabold text-gray-900">Collections</h2>
  
            <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-2">
              {arts.data?.map((callout) => (
                <div key={callout.arts_name} className="group relative pb-3">
                  <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 ">
                  {/* sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1 */}
                    <img
                      src={callout.path}
                      alt={callout.arts_name}
                      className="w-full h-full object-center object-cover"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    <a href={'#'}>
                      <span className="absolute inset-0" />
                      {callout.arts_name}
                    </a>
                  </h3>
                  {/* <p className="text-base font-semibold text-gray-900">{'Commission Arts'}</p> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  