// import $ from "jquery";
// import moment from 'moment';
export function formatDate(d) {
	var date = new Date(d);
  
	if (isNaN(date.getTime())) {
	  return d;
	}
	else {
  
	  var month = [];
	  month[0] = "January";
	  month[1] = "February";
	  month[2] = "March";
	  month[3] = "April";
	  month[4] = "May";
	  month[5] = "June";
	  month[6] = "July";
	  month[7] = "August";
	  month[8] = "September";
	  month[9] = "October";
	  month[10] = "November";
	  month[11] = "December";
  
	  let day = date.getDate().toString();
  
	  if (day < "10") {
		day = "0" + day;
	  }
  
	  return day + " " + month[date.getMonth()] + " " + date.getFullYear();
	}
  
  }

export function inputValidation(text, type) {
    var msg = "",
        inc = 0;
    if (text.length == 0 && type == "required") {
        msg = "is a required field";
    }
    return msg;
}

export function convertBytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}
// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export function convertArrayOfObjectsToCSV(array,data) {
    	let result;
    
    	const columnDelimiter = ',';
    	const lineDelimiter = '\n';
    	const keys = Object.keys(data[0]);
    
    	result = '';
    	result += keys.join(columnDelimiter);
    	result += lineDelimiter;
    
    	array.forEach(item => {
    		let ctr = 0;
    		keys.forEach(key => {
    			if (ctr > 0) result += columnDelimiter;
    
    			result += item[key];
    			
    			ctr++;
    		});
		result += lineDelimiter;
    	});
    
    	return result;
    }
    
    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    export function downloadCSV(array) {
    	const link = document.createElement('a');
    	let csv = convertArrayOfObjectsToCSV(array);
    	if (csv == null) return;
    
    	const filename = 'export.csv';
    
    	if (!csv.match(/^data:text\/csv/i)) {
    		csv = `data:text/csv;charset=utf-8,${csv}`;
    	}
    
    	link.setAttribute('href', encodeURI(csv));
    	link.setAttribute('download', filename);
    	link.click();
    }
    
    
   export const Export = ({ onExport }) => <button onClick={e => onExport(e.target.value)}>Export</button>;
    
// export function formatDate(datetime, format) {
//      if (datetime) {
//          if (format == "hh:mm a") {
//              return moment(datetime).format(format).toUpperCase();
//          } else {
//              return moment(datetime).format(format);
//          }
//      }
// }

// export function timeAgo(datetime) {
//     return moment(datetime).fromNow();
// }

// export function getColorByPriorityLevel(priorityLevel){
//         var colorName;

//         switch(priorityLevel) {
//             case 1:
//                 colorName = 'green';
//                 break;
//             case 2:
//                 colorName = 'red';
//                 break;
//             case 3:
//                 colorName = 'red';
//                 break;
//             default:
//                 colorName = 'green';
//         }
//         return colorName;
//     }
/* 
export function isUnreadPost (id,data) {
        if (data && data.length > 0 && data.find(x => x == id)) {
            return false
        } else {
            return true
      }
} */

//   export function userCount  (users) {
//         return users.length;
//     }

// export function userOtherCount(users) {
//         var userCount = users.length;
//         if(userCount > 1){
//             userCount = '+'+(userCount - 1);
//         }else{
//             userCount = '';
//         }
//         return userCount;
// }
// export function isVideo(url){
//     return (url && url.toString().match(/\.(avi|mp4|mpg|wmv|gifv|mkv|webm|3gp)$/) != null);
//   }
  
//   export function isYoutube(val){
//     var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
//     var match = val ? val.toString().match(regExp) : '';
//     if (match && match[2].length == 11) {
//       return true;
//     }
//   }
  
//   export function isVimeo(url){
//     var regExp2 = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
//     var match2 = url ? url.toString().match(regExp2) : '';
//     if (match2){
//       return true;
//     }
//   }
  
  
  export function getYoutubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
  
    return match && match[2].length === 11 ? match[2] : null;
  };

  

// export function isTypeFile(typeFile) {
//     var data = "";
//     if (((typeFile && typeFile.toString().match(/./g)) || []).length > 0) {
//         data = typeFile.toString().split(".");
//         data = data[data.length - 1];
//     }
//     return data;
// }

// export async function callVimeoImage(vimeoVideoID,id){
//     let videoImg =""
//     await $.getJSON('https://vimeo.com/api/v2/video/' + vimeoVideoID + '.json', {format: "json"}, function(data) {
//       videoImg = data[0].thumbnail_large;
//     });
//     $(".vimeo_"+id).attr("src", videoImg);
//     return videoImg;
//   }

//   export function getImageAtt(val,dimension,cls){
//     if(val) {
//       var extension = val.split('.').pop().toLowerCase();
//       var all = ['mp4', 'mov', 'mkv', 'txt', 'rtf', 'log', 'vsd', 'vsdx', 'vsdm', 'xml'];
//       var image = '/packages/carii_theme/public/images/attacment/default_file.jpg';
//       var img = ['jpeg', 'jpg', 'gif', 'bmp', 'png', 'webp'];
  
  
//       var doc = ['doc', 'docx', 'wps'];
//       var xls = ['xls', 'xlsx', 'csv'];
//       var ppt = ['ppt', 'pptx'];
//       var pdf = ['pdf'];
  
//       if (val.search("blob") != -1) {
//         image = val;
//       }
//       if (img.includes(extension)) {
//         image = val;
//       }
  
//       if (doc.includes(extension)) {
//         image = 'https://www.connective.network/packages/carii_theme/public/images/attacment/docx.png';
//       }
//       if (xls.includes(extension)) {
//         image = 'https://www.connective.network/packages/carii_theme/public/images/attacment/xlsx.png';
//       }
//       if (ppt.includes(extension)) {
//         image = 'https://www.connective.network/packages/carii_theme/public/images/attacment/pptx.png';
//       }
//       if (pdf.includes(extension)) {
//         image = 'https://www.connective.network/packages/carii_theme/public/images/attacment/pdf.png';
//       }
  
//       /*if(dimension){
//        image = getThumbnailUrl(image,dimension);
//        }*/
//       var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
//       var match = val ? val.toString().match(regExp) : '';
  
//       var regExp2 = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
  
//       var match2 = val ? val.toString().match(regExp2) : '';
  
//       if (match && match[2].length == 11) {
//         var youtube_video_id = getYoutubeId(val); //val.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
  
//         if (youtube_video_id && youtube_video_id.length == 11) {
//           var video_thumbnail = '//img.youtube.com/vi/'+youtube_video_id+'/0.jpg';
//           return video_thumbnail;
  
//         }
//       }else if (match2 && match2[1]){
  
//         var contetData = val.split('/');
  
//         var vimeoVideoID = contetData[4];
  
//         var videoImg = 'https://www.connective.network/packages/carii_theme/public/images/default_video.jpg';
  
//         videoImg =  callVimeoImage(vimeoVideoID,cls);
  
  
  
//         return videoImg
//       }
  
  
//       //return image;
//     }
//   }
  
//   export function callImage(file) {
//     if(file){
//       if(file.attachmentType && file.attachmentType.attachmentTypeCode ==1){
//         return file.url;
//       }else if(file.attachmentType && file.attachmentType.attachmentTypeCode ==2){
  
//         return "https://www.connective.network/packages/carii_theme/public/images/new_icon_version_v3_1/icon-files/file-" + isTypeFile(file.name)+".jpg"
  
//       }else if(isVimeo(file.url)){
//         return getImageAtt(file.url,'',file._id);
//       }else if(isYoutube(file.url)){
//         return getImageAtt(file.url,'',file._id);
//       }else if(isVideo(file.url)){
//         return file.thumbnail
//       }
//     }else{
//       return "https://via.placeholder.com/256x256/333333/666666/?text=LOGO"
//     }
  
//   }
  
//   export function getVideo(url){
//     let regExp2 = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
//     let match2 = url ? url.toString().match(regExp2) : '';
//     if (match2){
//       url = 'https://player.vimeo.com/video/'+match2[1]+'?autoplay=0&loop=1&autopause=1';
//     }else{
//       let matchyoutube = url.toString().match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
  
//       if(matchyoutube){
//         let youtube_video_id = matchyoutube.pop();
//         if (youtube_video_id && youtube_video_id.length == 11) {
//           url = 'https://www.youtube.com/embed/' + youtube_video_id + '?enablejsapi=1';
//         }
//       }else{
//         url = url;
//       }
//     }
  
//     return url;
  
//   }
  
// export function isAddComma(index, postTopic) {
//     if(postTopic){
//       let postTopic_ = postTopic.filter(function(item){
//         return item? item.categoryName != "knowledgebank" : "";
//       })
//       if (postTopic_) {
//           var countTopic = postTopic_.length,
//               counter = index + 1;
//           if (countTopic > 1) {
//               if (counter < countTopic) {
//                   return true;
//               }
//           }
//       }
//     }
// }

// export function topicDisplay (postTopic) {
//   if(postTopic){
//     var postTopic_ = postTopic.filter(function (obj) {
//         return obj ? obj.categoryName != "knowledgebank" : "";
//     });
//     return postTopic_;
//   }
// }


// export function htmlTotextLimitHtml(html, limit, mark) {
//     if (html != undefined) {
//         html = html.replace(/<\/?iframe>/g, "");

//         var textLength = html.replace(/(<([^>]+)>)/gi, "").length;
//         var res = html.split("<div>");
//         var final = "";
//         if (res.length > 3) {
//             final += res[0].replace(/(<([^>]+)>)/gi, "") + "<br>";
//             final += res[1].replace(/(<([^>]+)>)/gi, "") + "<br>";
//             final += res[2].replace(/(<([^>]+)>)/gi, "") + "<br>";
//         } else {
//             res.forEach(function (text) {
//                 if (text) {
//                     final += text.replace(/<(?!br\s*\/?)[^>]+>/g, "");
//                 }
//             });
//         }

//         var res2 = final.split("<br>");
//         if (textLength > limit || final.length > limit) {
//             final = final.substring(0, limit) + mark;
//         } else if (res.length > 3 || res2.length > 3) {
//             if (res2.length > 3) {
//                 final = "";
//                 final += res2[0].replace(/(<([^>]+)>)/gi, "") + "<br>";
//                 final += res2[1].replace(/(<([^>]+)>)/gi, "") + "<br>";
//                 final += res2[2].replace(/(<([^>]+)>)/gi, "") + "<br>";
//             }
//             final = final + mark;
//         } else {
//             final = html;
//         }

//         /*<(div|/div|br|p|/p)[^>]{0,}>*/

//         var regex = "/<(iframe|/iframe)[^>]{0,}>/g";
//         var result = final.replace(regex, "");


//         result = result.replace(/<[^>]+>/g, "");


//         return result;
//     }
// };

// export function htmlTotext(html) {
//   if (html != undefined) {
//       html = html.replace(/<\/?iframe>/g, "");

//       var textLength = html.replace(/(<([^>]+)>)/gi, "").length;
//       var res = html.split("<div>");
//       var final = "";
//       if (res.length > 3) {
//           final += res[0].replace(/(<([^>]+)>)/gi, "") + "<br>";
//           final += res[1].replace(/(<([^>]+)>)/gi, "") + "<br>";
//           final += res[2].replace(/(<([^>]+)>)/gi, "") + "<br>";
//       } else {
//           res.forEach(function (text) {
//               if (text) {
//                   final += text.replace(/<(?!br\s*\/?)[^>]+>/g, "");
//               }
//           });
//       }

//       var res2 = final.split("<br>");
//       if (res.length > 3 || res2.length > 3) {
//           if (res2.length > 3) {
//               final = "";
//               final += res2[0].replace(/(<([^>]+)>)/gi, "") + "<br>";
//               final += res2[1].replace(/(<([^>]+)>)/gi, "") + "<br>";
//               final += res2[2].replace(/(<([^>]+)>)/gi, "") + "<br>";
//           }
//           final = final;
//       } else {
//           final = html;
//       }

//       var regex = "/<(iframe|/iframe)[^>]{0,}>/g";
//       var result = final.replace(regex, "");


//       result = result.replace(/<[^>]+>/g, "");


//       return result;
//   }
// };


// export  function date_show(_date,part) {
//     if(_date){
//         let date =new Date(_date);
//         var day = date.getDate();
//         var month = date.getMonth();
//         var Year = date.getFullYear();
//         var months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
//         if(part=="day")return day;
//         if(part=="month")return months[month];
//         if(part=="year")return Year;
//     }
// }

// export function formatDateTimeDisplay(dateStr) {
//     let date=new Date(dateStr)
//     let options = { year: 'numeric', month: 'long', day: 'numeric',hour: 'numeric', minute: 'numeric',second: 'numeric'};
//     let result  = new Date(date);
//     return result.toLocaleDateString("en-US", options)
//   }

// export function getHost_demo (domain) {
//     /* 
//         domain === "demo.localhost.com" ||
//       domain === "demo.carii.pro" ||
//       domain === "demo.connective.network" */

//     if (domain === "demo.dev.carii.pro" || domain === "demo.qa.connective.network") {
//         return true;
//       }
//     return false;
// };

// export function getHost_demoDEV (domain) {
//   if (domain === "demo.dev.carii.pro") {
//       return true;
//     }
//   return false;
// };

// export function getHost_ojwLive(domain){
//   if (domain === "ojw.connective.network") {
//       return true;
//     }
//   return false;
// };

// export function getHost_support (domain) {
//    /* domain === "support.carii.pro" ||
//     domain === "support.localhost.com" ||
//     domain === "support.dev.carii.pro" || */
//   if ( domain === "support.qa.connective.network" ||domain === "support.connective.network") {
//       return true;
//   }
//   return false;
// };

// export function getHost_futurenow (domain) {
//   if (
//     domain === "futurenow.localhost.com" ||
//     domain === "futurenow.dev.carii.pro" ||
//     domain === "futurenow.qa.connective.network" ||
//     domain === "futurenow.carii.pro" ||
//     domain === "futurenow.connective.network"
// ) {
//     return true;
// }
//   return false;
// };

// export function getHost_ojw (domain) {
//   if (
//     domain === "ojw.localhost.com" ||
//     domain === "ojw.dev.carii.pro" ||
//     domain === "ojw.qa.connective.network" ||
//     domain === "ojw.carii.pro" ||
//     domain === "ojw.connective.network"
// ) {
//     return true;
// }
//   return false;
// };

// export function isAllowEditComponentLib (domain) {
//   //if (domain && (getHost_support(domain) || getHost_futurenow(domain) || getHost_demo(domain))) {
//   if (domain && (getHost_support(domain) || getHost_demo(domain))) {
//       return true;
//   }
//   return false;
// };

// export function isAllowLoadAllLib (domain) {
//   if (domain && (getHost_support(domain) || getHost_demo(domain))) {
//       return true;
//   }
//   return false;
// };

// export function isAllowDeployAllLib (domain) {
//   if (domain && (getHost_demoDEV(domain))) {
//       return true;
//   }
//   return false;
// };


// export function isHomeBuilder(user) {
//   let _users = user 
//   let result = false

//   if (_users && _users.userType && _users.userType.length > 0) {
//     _users.userType.forEach(function (item) {
//       if (item.userTypeName == "Home Builder") {
//         result = true
//       }
//     })
//   }

//   return result
// }

// export function getSizeIcon(heightofImage,widthofImage) {
//   let cssSize={height: "20px",width: "20px"}
//   if(heightofImage)cssSize["height"]=heightofImage+"px"
//   if(widthofImage)cssSize["width"]=widthofImage+"px"
//   return cssSize;
// }

// export function getSizeFont(heightofImage,widthofImage) {
//   let cssSize={fontSize: "20px"}
//   if(heightofImage)cssSize["fontSize"]=heightofImage+"px"
//   return cssSize;
// }


// export function isUnreadPost(postData,userId) {
//   let _isReadPost=""
//   if(postData && postData.viewBy && userId){
//     _isReadPost=postData.viewBy.filter((item) => item._id === userId);
//   }
//   if(_isReadPost && _isReadPost.length>0){
//     if (_isReadPost=="") {
//       return true;
//     } else {
//         if (userId && _isReadPost[0]._id==userId) {
//           return false;
//         } else {
//           return true;
//         }
//     }
//   }
// }

// export function MediaShow (val){
//   let url = val.url;
//   if(url) {
//       if (isYoutube(url) || isVimeo(url) || isVideo(url)) {
//           return (<iframe
//               allowFullScreen={1}
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//               title="YouTube video player"
//               src={getVideo(url)}
//               width="100%"
//               height="100%"
//               frameBorder={0}
//           ></iframe>)
//       } else {
//           return (<img
//               src={url} alt="" className="img-responsive"
//           />)
//       }
//   }
// };

// export function htmlTotextLimitWordForKnowledgeBank(html, limit, mark) {
//   let text = html;
//   if (text) {
//       html = html.replace(/(<iframe.*?>.*?<\/iframe>)/g, "");
//       text = html.replace(/(<iframe([^>]+)>)/gi, "&nbsp;");
//       text = text.replace(/(<br([^>]+)>)/gi, "&nbsp;");
//       text = text.replace(/(<p([^>]+)>)/gi, "&nbsp;");
//       text = text.replace("&nbsp;&nbsp;", "&nbsp;");
//       text = text.replace(/(<([^>]+)>)/gi, "");

//       html = html.replace(/<img[^>]*>/g, "");
//       html = html.replace(/style="[^"]*"/g, "");
//       html = html.replace(/dir="[^"]*"/g, "");
//       html = html.replace(/class="[^"]*"/g, "");
//       html = html.replace(/data-elementfor="[^"]*"/g, "");

//       html = html.replace(/<\/?h1>/g, "");
//       html = html.replace(/<\/?h2>/g, "");
//       html = html.replace(/<\/?h3>/g, "");
//       html = html.replace(/<\/?h4>/g, "");
//       html = html.replace(/<\/?h5>/g, "");

//       html = html.replace(/(<h1([^>]+)>)/gi, "");
//       html = html.replace(/(<h2([^>]+)>)/gi, "");
//       html = html.replace(/(<h3([^>]+)>)/gi, "");
//       html = html.replace(/(<h4([^>]+)>)/gi, "");
//       html = html.replace(/(<h5([^>]+)>)/gi, "");
//       html = html.replace(/<br[^>]*>/gi, "");

//       if (text.length > limit) {
//           html = html.trim().split(" ");
//           html = html.splice(0, limit).join(" ");
//           html = html.substring(0, limit);
//           html = html + mark;
//       }
//       return html;
//   }
// }


// export function checkIsNeedBlankComponent(expData){
//   let IsHeaderExist =false, IsFooterExist=false
//   //console.log("expData",expData);
//   if(expData && expData.items && expData.items.length==2){
//       let HeaderExist=expData.items.find((x) =>x && x.type ==='header')
//       let FooterExist=expData.items.find((y) =>y && y.type ==='footer')
//       if(HeaderExist && HeaderExist._id)IsHeaderExist=true
//       if(FooterExist && FooterExist._id)IsFooterExist=true
//       //console.log("IsFooterExist",IsFooterExist);
//       //console.log("IsHeaderExist",IsHeaderExist);
//   }
//   if(IsHeaderExist===true && IsFooterExist===true)return true
//   else return false
// }


// export function addingCss(cssInline = {},paddingTopCss){
//   let cssResult=cssInline

//   if(paddingTopCss && paddingTopCss.schema && paddingTopCss.schema.css && paddingTopCss.schema.css.marginTop && paddingTopCss.schema.css.marginTop)cssResult["paddingTop"]=paddingTopCss.schema.css.marginTop+"px"
//   else cssResult["paddingTop"]="0px"

//   return cssResult
// }