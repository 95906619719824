import Header from "../../../../Header";
import AssetsCon from "./Assets"
function AssetsRoutes() {
    return(
        <>
        <Header />
        <AssetsCon/>
        
        </>
    )
}
export default AssetsRoutes;