import DataTable from 'react-data-table-component';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { downloadCSV, Export } from '../../../Middleware/helpers/global';
import * as queue_act from '../../../Middleware/action/queue.action';
import { formatDate } from '../../../Middleware/helpers/global';
// import { $ } from 'jquery'


function Queue() {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const queue = useSelector((state) => (state.queue));
  const dispatch = useDispatch();
  const ClearButton = styled.button`
  	border-top-left-radius: 0;
  	border-bottom-left-radius: 0;
  	border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  	height: 34px;
  	width: 32px;
  	text-align: center;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  `;
  function change(params) {
    setFilterText(params)
  }
  // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(filteredItems)} />, []);
  const customStyles = {
    header: {
      style: {
        minHeight: '56px',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        // borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };

  useEffect(() => {
    dispatch(queue_act.GetAll())
  }, [])

  useEffect(() => {
    // setData([])
    if (queue.data.length !== 0) {
      setColumns([
        {
          name: 'No',
          selector: row => <div style={{ display: "flex", justifyContent: "center" }}>
            {row.no}
          </div>,
          sortable: true,
          maxWidth: "5% !important",
          center: true
        },
        {
          name: 'Client Name',
          selector: row => row.clientname,
          sortable: true,
          center: true,
          maxWidth: "15% !important",
        },
        {
          name: 'Commission',
          selector: row => row.commission,
          sortable: true,
          maxWidth: "15% !important",
          center: true
        },
        {
          name: "Status",
          selector: row =>
            <div className="stepper-wrapper" style={{ paddingTop: "2vh" }}>
              <div className={`stepper-item ${row.status == "Queue" || row.status == "On Progress" || row.status == "Completed"
                ? `completed ${row.status == "Completed" || row.status == "On Progress" ? "itemz" : ""}`
                : ""
                }`}>
                <div className="step-counter">1</div>
                <div className="step-name text-center">Queue</div>
              </div>
              <div className={`stepper-item ${row.status == "On Progress" || row.status == "Completed"
                ? `completed ${row.status == "Completed" ? "itemz" : "itemMid"}`
                : ""
                }`}>
                <div className="step-counter ">2</div>
                <div className="step-name text-center">On Progress</div>
              </div>
              <div className={`stepper-item ${row.status == "Completed"
                ? "completed itemz"
                : ""
                }`}>
                <div className="step-counter">3</div>
                <div className="step-name text-center">Completed</div>
              </div>
            </div>,
          sortable: true,
          // maxWidth: "55% !important"
        },
        // {
        //   name: 'Estimated',
        //   selector: row => formatDate(row.date),
        //   sortable: true,
        //   maxWidth: "10% !important"
        // },
      ]);
      // $(".jfrgbA").children().addClass("w-100")
      setPending(false)

    }
    if (queue) {
      queue.data?.map((callout, index) => {
        let com = "";
        let stat = "";

        if (callout.commission == 1) {
          com += 'Vtube';
        } else if (callout.commission == 2) {
          com += 'Logo';
        } else if (callout.commission == 3) {
          com += 'Overlay';
        } else if (callout.commission == 4) {
          com += 'Arts';
        } else if (callout.commission == 5) {
          com += 'Emote';
        } else {
          com += 'Assets';
        }

        if (callout.status == 1) {
          stat += `Queue`;
        } else if (callout.status == 2) {
          stat += 'On Progress';
        } else {
          stat += 'Completed';
        }

        let queue = {
          no: index + 1,
          clientname: callout.clientname,
          commission: com,
          status: stat,
          // date: callout.date,
        }
        data.push(queue)
      })


    }
  }, [queue]);

  const filteredItems = data.filter(
    item => item.clientname && item.clientname.toLowerCase().includes(filterText.toLowerCase())
      || item.status && item.status.toLowerCase().includes(filterText.toLowerCase()),
  );
  return (
    <div className="relative bg-white overflow-hidden mt-10  p-10 animate-fade-right">
      <div className="flex flex-col ...">
        <div className="text_banner text-center mt-5"> Queue Progress List  </div>

        <DataTable columns={columns} data={filteredItems} progressPending={pending}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          customStyles={customStyles}
          subHeaderComponent={
            <>
              <input
                id="search"
                type="text"
                placeholder="Filter By Client Name"
                aria-label="Search Input"
                value={filterText}
                onChange={e => change(e.target.value)}
              />
              <ClearButton type="button" onClick={handleClear}>
                X
              </ClearButton>
            </>
          }
          // selectableRows
          persistTableHead
          pagination
        // actions={actionsMemo}
        />


      </div>
    </div>
  )
}

export default Queue