import Header from "../../../../Header";
import BaneConAnim from "./BaneConAnim"
function BaneConAnimRoutes() {
    return(
        <>
        <Header />
        <BaneConAnim/>
        
        </>
    )
}
export default BaneConAnimRoutes;