import Rules from './Rules';
import Header from '../../Header';

function RulesRoutes() {
    return(
<>
<Header />
<Rules />
</>
    )
}
export default RulesRoutes;