import Upload from "../../Slide_over/Upload";
function Admin() {
    return(
        <>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg carosul">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Vtuber Model's (Full Body)</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Code_Name : Reviz</p>
      </div>
      <div className="border-t border-gray-200">
        <dl className=''>
        <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="grid gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                        Code_Name
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                      <input type="text"  className='inp_cons border'/>
                      </div>
                    </div>
                  </div>

                  <div>
                  <div className="grid gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <div className="mt-1 flex rounded-md shadow-sm">
                      <h4 className='text-gray-500'>Full Body</h4><input type="radio" name='type' className='check_cons'/> 
                              <h4 className='text-gray-500'>Half Body</h4><input type="radio"  name='type' className='check_cons'/> 
                      </div>
                    </div>
                  </div>
                  </div>

               <div>
                   <Upload/>
               </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </dl>
      </div>
    </div>
      </>
    )
}

export default Admin;