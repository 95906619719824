/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { useSelector, useDispatch } from 'react-redux';
import Footer from './Component/Footer'
import ProfileRoutes from './Component/Container/Profile/ProfileRoutes'
import VtuberRoutes from './Component/Container/Arts/Vtuber/Vtuber_model/VtuberRoutes';
import ArtsRoutes from './Component/Container/Arts/Vtuber/Arts/ArtsRoutes';
import RiggingRoutes from './Component/Container/Arts/Vtuber/Rigging/RiggingRoutes';
import BaneConRoutes from './Component/Container/Arts/Vtuber/Banner_Icon/BaneConRoutes';
import BaneConAnimRoutes from './Component/Container/Arts/Vtuber/Banner_Icon_Animated/BaneConAnimRoutes';
import AssetsRoutes from './Component/Container/Arts/Vtuber/Assets/AssetsRoutes';
import OverlayRoutes from './Component/Container/Arts/Vtuber/Overlay/OverlayRoutes';
import PricingRoutes from './Component/Container/Pricing/PricingRoutes';
import AdminRoutes from './Component/Container/Admin/AdminRoutes';
import QueueRoutes from './Component/Container/Queue/QueueRoutes';
import RulesRoutes from './Component/Container/Rules/RulesRoutes';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './Middleware/store';

import * as arts_act from './Middleware/action/arts.action';
import * as logobanner_act from './Middleware/action/logobanner.action';
import * as event_act from './Middleware/action/event.action';

function Route_path() {
  const dispatch = useDispatch();
  const { vtuber, arts, logobanner,event } = useSelector((state) => ({
    vtuber: state.vtuber,
    arts: state.arts,
    logobanner: state.logobanner,
    event: state.event,
  }));

  useEffect(() => {
    // dispatch(vtuber_act.GetAll())
    dispatch(event_act.GetAll())
  }, [])

  // useEffect(() => {
  //   console.log("event", event)
  // }, [event])


  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Navigate to="/Profile" />} />

        <Route exact path="/Profile" element={<ProfileRoutes />} />
        <Route exact path="/Vtuber" element={<VtuberRoutes />} />
        <Route exact path="/Arts" element={<ArtsRoutes />} />
        <Route exact path="/Rigging" element={<RiggingRoutes />} />
        <Route exact path="/Baner_Icon" element={<BaneConRoutes />} />
        <Route exact path="/Baner_Icon_Animated" element={<BaneConAnimRoutes />} />
        <Route exact path="/Pricing" element={<PricingRoutes />} />
        <Route exact path="/Rules" element={<RulesRoutes />} />
        <Route exact path="/Queue" element={<QueueRoutes />} />
        <Route exact path="/Assets" element={<AssetsRoutes />} />
        <Route exact path="/Overlay" element={<OverlayRoutes />} />
        <Route exact path="/Admin" element={<AdminRoutes />} />
      </Routes>
    </Router>
  )
}


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Route_path />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


