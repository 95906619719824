import { FindAll } from '../services/arts.service';
import * as type from '../type';
import *  as constant from '../constant';


export function GetAllPersonalArts() {
  return dispatch => {
    FindAll(constant.GETPERSONALARTS).then(data => {
      dispatch({
        type: type.LIST_PERSONALARTS,
        data: data
      });
    });
  }
}

export function GetAllCommisionArts() {
  return dispatch => {
    FindAll(constant.GETCOMARTS).then(data => {
      dispatch({
        type: type.LIST_COMMISIONARTS,
        data: data
      });
    });
  }
}




// export async function CreateTokenAction(paramCard, publickey) {
//   let result = await CreateToken(paramCard, publickey);
//   return result;
// }


