/* eslint-disable no-mixed-operators */
/* This example requires Tailwind CSS v2.0+ */
import golden from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Golden Banner.png"
import zion from "./../../../../Img/Arts/Commish/Banner and Logo Commission/zion.png"
import kenna from "./../../../../Img/Arts/Commish/Banner and Logo Commission/kenna.png"
// import bonnnoujimb from "./../../../../Img/Arts/Commish/Banner and Logo Commission/bonnoujimb.png"
// import hummer from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Hummer.jpg"

// import aruka from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Aruka Rabinal Logo Commission by Constellatia.jpg"
// import asc from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Ascended Logo.png"
// import Hajitomi from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Hajitomi Logo by Constellatia.jpg"
// import Hoshi from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Hoshi Roger Logo by Constellatia.jpg"
// import kumara from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Kumara Terra Logo by Constellatia.jpg"

// import MaouJay from "./../../../../Img/Arts/Commish/Banner and Logo Commission/MaouJay Logo by Constellatia.jpg"
// import Nekoto from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Nekoto Ria Logo by Constellatia.jpg"
// import Togetsu from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Togetsu Ouka Logo Commission by Constellatia.jpg"
// import 怪盗義賊あいう from "./../../../../Img/Arts/Commish/Banner and Logo Commission/怪盗義賊あいう Logo Commission by Constellatia.jpg"
// import yagami from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Yagami Noah Logo.png"
import { useSelector, useDispatch } from 'react-redux';
import * as logoBanner_act from '../../../../../Middleware/action/logobanner.action';
import React, { useEffect, useState } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
function BaneCon() {

  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(true);
  const logoBanner = useSelector((state) => (state.logobanner.data));
  useEffect(() => {
    dispatch(logoBanner_act.GetAll())
  }, [])
  useEffect(() => {
    if (logoBanner.filter(a => (a.animated === false || a.animated === "false").length > 0)) {
      setTimeout(() => {
        setLoading(false)
      }, 1000);
    }
  }, [logoBanner])
  return (
    <div className="relative bg-white overflow-hidden mt-10  p-10 animate-fade-right">
      <div className="flex flex-col ...">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1 style={{ fontSize: "35px" }}>Logo Banner Non Animated</h1>
        </div>


        {Loading
          ? <>
            <h1 className='animate-pulse'>LOADING...</h1><br></br>
            <ResponsiveMasonry
              // columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
              columnsCount={3} gutter="10px"
            >
              <Masonry>
                <img
                  src={golden}
                  style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
                  className='animate-pulse '
                  alt=""
                />
                <img
                  src={zion}
                  style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
                  className='animate-pulse '
                  alt=""
                />
                <img
                  src={kenna}
                  style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
                  className='animate-pulse '
                  alt=""
                />
              </Masonry>
            </ResponsiveMasonry>
          </>
          :
          <ResponsiveMasonry>
            <Masonry>
              {logoBanner.filter(a => (a.animated === false || a.animated === "false")).map((image, i) => (
                <img
                  key={i}
                  src={image.path}
                  style={{ width: "100%", display: "block" }}
                  alt=""
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        }



      </div>
    </div >
  )
}
export default BaneCon;