import Modal_large from "../../../../Modal.js/Modal_large";
import { Fragment, useState, useRef } from 'react'
import { XIcon } from '@heroicons/react/outline'
import Collection from "./Collection";
import Hg1 from "./../../../../Img/Arts/Commish/Furry- Monster Commission/Hg1.webp"
import Hg2 from "./../../../../Img/Arts/Commish/Furry- Monster Commission/Hg2.jpg"
import Hg3 from "./../../../../Img/Arts/My_arts/Hg3.jpg"
import Hg4 from "./../../../../Img/Arts/My_arts/Hg4.jpeg"
import Hg5 from "./../../../../Img/Arts/My_arts/Hg5.jpg"
import MyArts from "./Myarts";

function Arts() {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div className="relative bg-white overflow-hidden mt-10 animate-fade-right">
        <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
            <div className="sm:max-w-lg">
              <h1 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-6xl">
                ‟There is no healthier drug than creativity”
              </h1>
              <p className="mt-4 text-xl text-gray-500">
                -Nayyirag Waheed-
                <br />
                Here's are my art's collection below
              </p>
            </div>
            <div>
              <div className="mt-10">
                {/* Decorative image grid */}
                <div
                  aria-hidden="true"
                  className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
                >
                  <div style={{ top: "100%" }} className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                          <img
                            src={Hg1}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src={Hg2}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src={Hg5}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>

                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src={Hg4}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src={Hg3}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <a
                  href="#"
                  className="absolute mb-3 inline-block text-center bg-indigo-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-indigo-700"
                  onClick={() => setOpen(true)}
                >
                  Arts Collection
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal_large Isopen={open} contents={<Collection />}>
        <button
          type="button"
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
          onClick={() => setOpen(false)}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </Modal_large>
    </>
  )
}

export default Arts;