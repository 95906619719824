import Header from "../../../../Header";
import Arts from "./Arts";
function ArtsRoutes() {
    return(
        <>
        <Header />
        <Arts />
        </>
    )
}

export default ArtsRoutes;