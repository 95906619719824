/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect } from 'react'
import { Popover, Transition, Menu } from '@headlessui/react'
import {
  // BookmarkAltIcon,
  CalendarIcon,
  // ChartBarIcon,
  // CursorClickIcon,
  MenuIcon,
  // PhoneIcon,
  // PlayIcon,
  // RefreshIcon,
  // ShieldCheckIcon,
  // SupportIcon,
  // ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon, LockClosedIcon } from '@heroicons/react/solid'
// import Header_Opt from './Header_opt'
import { useSelector, useDispatch } from 'react-redux';
import logo from './Img/Constellatia Logo.png'
import { NavLink } from "react-router-dom";
import Modal_login from './Modal.js/Modal_login'
import { createUserWithEmailAndPassword, onAuthStateChanged, signOut, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from './../Firebase-config.js'
// import * as login_Firebase_Act from "../Middleware/action/login.action"

import vtubeIcon from './Img/Icon/PLANET3.png'
import logoIcon from './Img/Icon/PLANET4.png'
import overlayIcon from './Img/Icon/PLANET2.png'
import animIcon from './Img/Icon/PLANET1.png'
import liveIcon from './Img/Icon/PLANET5.png'
import artsIconIcon from './Img/Icon/PLANET6.png'
import assetsIcon from './Img/Icon/PLANET7.png'
import nonAnimIcon from './Img/Icon/PLANET8.png'
import profileIcon from './Img/Icon/cheesecake.png'
import coin from './Img/Icon/coin.png'
import rule from './Img/Icon/rule.png'
import queue from './Img/Icon/queue.png'
import { Nav } from 'react-bootstrap'

const solutions = [
  {
    name: "Pricing",
    description: 'Detail Price.',
    href: '/pricing',
    icon: coin,
    class: "sun"
  },
  {
    name: "Rules",
    description: 'Detail Rules.',
    href: '/rules',
    icon: rule,
    class: "earth"
  },
  {
    name: "Arts",
    description: 'Personal Arts and Commission Arts.',
    href: '/Arts',
    icon: artsIconIcon,
    class: "sun"
  },
  {
    name: "Vtube Models",
    description: 'Personal and Commission Vtube Models.',
    href: '/Vtuber',
    icon: vtubeIcon,
    class: "earth"
  },
  {
    name: 'Logo and Banner',
    description: "Logos and Banners.",
    href: '/Baner_Icon',
    icon: logoIcon,
    class: "yupiter",
    child: [
      {
        name: 'Non Animated',
        description: "Non Animated Logos and Banners.",
        href: '/Baner_Icon',
        icon: nonAnimIcon,
        class: "saturnus",
      },
      {
        name: 'Animated',
        description: "Animated Logos and Banners.",
        href: '/Baner_Icon_Animated',
        icon: animIcon,
        class: "sun",
      }
    ]
  },
  {
    name: 'Live2D Showcase',
    description: "Live2D Showcase Videos.",
    href: '/Rigging',
    icon: liveIcon,
    class: "saturnus"
  },
  {
    name: 'Overlay',
    description: "Non Animated & Animated Overlays.",
    href: '/Overlay',
    icon: overlayIcon,
    class: "yupiter"
  },
  {
    name: 'Asset',
    description: "Non Animated & Animated Assets.",
    href: '/Assets',
    icon: assetsIcon,
    class: "earth"
  },

]

const resources = [
  {
    name: 'Events',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: CalendarIcon,
  },
]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
function Login() {
  const [LoginEmail, setLoginEmail] = useState("");
  const [LoginPassword, setLoginPassword] = useState("");
  const [user, setUser] = useState({})
  const dispatch = useDispatch();

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser)
  })

  const logout = async () => {
    await signOut(auth)
  }

  const register = async () => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth, LoginEmail, LoginPassword
      );
      console.log(user)
    } catch (error) {
      console.log(error)
    }
  }
  const login = async () => {
    // dispatch(login_Firebase_Act.GetLoginFirebase(LoginEmail, LoginPassword))
    // try {
    //   const user = await signInWithEmailAndPassword(
    //     auth, LoginEmail, LoginPassword
    //   )
    //   console.log("user", user)
    //   alert("Successfully Login")
    // } catch (error) {
    //   console.log(error)
    // }
  }
  useEffect(() => {
    console.log(auth)
  }, [])

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto logo"
              src={logo}
              alt="Workflow"
            />
            {user
              ? <h2 className="mt-6 text-center md:text-3xl sm:text-lg font-extrabold text-gray-500">You logged in as</h2>
              : <h2 className="mt-6 text-center md:text-3xl sm:text-lg font-extrabold text-gray-500">Sign in to your account</h2>
            }
          </div>
          {user
            ? <div className=''>
              <h4 className="mt-6 text-center md:text-lg sm:text-md font-extrabold text-gray-500"> {user.email}</h4>
              <button className='mt-5 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-5 sm:ml-3 sm:text-sm' onClick={logout}>Sign Out</button>
            </div>
            : <form className="mt-8 space-y-6" action="#" method="POST">
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Username"
                    onChange={(event) => {
                      setLoginEmail(event.target.value)
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Password"
                    onChange={(event) => {
                      setLoginPassword(event.target.value)
                    }}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                  </a>
                </div>
              </div>
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500  sm:w-full sm:text-sm"
                onClick={login}
              >
                Login
              </button>
            </form>
          }

        </div>
      </div>
    </>
  )
}
export default function Header() {
  const [open, setOpen] = useState(false)
  const [openIconBanner, setOpenIconBanner] = useState(false)


  const cancelButtonRef = useRef(null)
  return (
    <>
      <Popover className="relative bg-white header_cust" style={{ zIndex: 9 }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center  py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <NavLink to={"/Profile"}
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Workflow</span>
                <img
                  className="h-8 w-auto logo"
                  src={logo}
                  alt=""
                />
              </NavLink>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
              <NavLink to="/Profile" className="text-base font-medium text-gray-500 hover:text-gray-900 text_header">
                {/* <img src={profileIcon} style={{ height: "35px", width: "35px" }} /> */}
                Profile
              </NavLink>
              {/* <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Docs
            </a> */}
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? 'text-gray-900' : 'text-gray-500',
                        'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      )}
                    >
                      <span className='text_header antialiased'>Constellatia's Art</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? 'text-gray-600' : 'text-gray-400',
                          'ml-2 h-5 w-5 group-hover:text-gray-500'
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">

                            {solutions.map((item, i) => (
                              item && item.child && item.child.length > 0
                                ?
                                <Menu as="div" className="relative inline-block text-left">
                                  <div>
                                    <a
                                      key={i}
                                      // to={item.href}
                                      className={"-m-3 p-3 flex items-start rounded-lg " + item.class + ""}
                                    >
                                      {/* <item.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" /> */}

                                      <Menu.Button style={{ justifyContent: "flex-start" }} className="inline-flex w-full  gap-x-1.5 rounded-md bg-white py-2 text-sm font-semibold text-gray-900  hover:bg-gray-50">
                                        <img src={item.icon} style={{ height: "35px", width: "35px" }} />
                                        <div className="ml-4">
                                          <p className="text-base font-medium text-gray-900">{item.name}</p>
                                          <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                        </div>
                                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                      </Menu.Button>
                                    </a>

                                  </div>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      style={{ width: "100%" }}
                                      className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        {item && item.child && item.child.length > 0 &&
                                          item.child.map((itemx, i) =>
                                          (
                                            <Menu.Item key={i}>
                                              {({ active }) => (
                                                <NavLink
                                                  to={itemx.href}
                                                  className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                  )}
                                                >
                                                  {itemx.name}
                                                </NavLink>
                                              )}
                                            </Menu.Item>
                                          ))}

                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                                :
                                <NavLink
                                  key={i}
                                  to={item.href}
                                  className={"-m-3 p-3 flex items-start rounded-lg " + item.class + ""}
                                >
                                  {/* <item.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" /> */}
                                  <img src={item.icon} style={{ height: "35px", width: "35px" }} />
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                  </div>

                                </NavLink>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              {/* <NavLink to="/Pricing" className="text-base font-medium text-gray-500 hover:text-gray-900 text_header">
                Pricing
              </NavLink> */}
              <a href="/Queue" className="text-base font-medium text-gray-500 hover:text-gray-900 text_header"
              // style={{ pointerEvents : "none", opacity : 0.5 }}
              >
                Client Queue
                {/* <small style={{ position : "absolute",top:"4.5vw" }}>(Under Construction)</small>  */}
              </a>
              {/* <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                  >
                    <span className='text_header'>More</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                          <div>
                            <h3 className="text-sm tracking-wide font-medium text-gray-500 uppercase">Recent Posts</h3>
                            <ul role="list" className="mt-4 space-y-4">
                              {recentPosts.map((post) => (
                                <li key={post.id} className="text-base truncate">
                                  <a href={post.href} className="font-medium text-gray-900 hover:text-gray-700">
                                    {post.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="mt-5 text-sm">
                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                              {' '}
                              View all posts <span aria-hidden="true">&rarr;</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover> */}
            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              {/* <a>{user?.email}</a> */}
            </div>

          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {/* for Mobile */}
          <Popover.Panel focus className="to_front absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div className='center_obj'>
                    <img
                      className="logo_menu"
                      src={logo}
                      alt="Constellatia"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="btn_burger rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {solutions.map((item, i) => (
                      item && item.child && item.child.length > 0
                        ?
                        <Menu as="div" className="relative inline-block text-left">
                          <div>
                            <a
                              key={i}
                              // to={item.href}
                              className={"-m-3 p-3 flex items-start rounded-lg " + item.class + ""}
                            >
                              {/* <item.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" /> */}

                              <Menu.Button style={{ justifyContent: "flex-start" }} className="inline-flex w-full  gap-x-1.5 rounded-md bg-white py-2 text-sm font-semibold text-gray-900  hover:bg-gray-50">
                                <img src={item.icon} style={{ height: "35px", width: "35px" }} />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </div>
                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                              </Menu.Button>
                            </a>

                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              style={{ width: "100%" }}
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                {item && item.child && item.child.length > 0 &&
                                  item.child.map((itemx, i) =>
                                  (
                                    <Menu.Item key={i}>
                                      {({ active }) => (
                                        <NavLink
                                          to={itemx.href}
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                          )}
                                        >
                                          {itemx.name}
                                        </NavLink>
                                      )}
                                    </Menu.Item>
                                  ))}

                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                        :
                        <NavLink
                          key={i}
                          to={item.href}
                          className={"-m-3 p-3 flex items-start rounded-lg " + item.class + ""}
                        >
                          {/* <item.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" /> */}
                          <img src={item.icon} style={{ height: "35px", width: "35px" }} />
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">{item.name}</p>
                            <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                          </div>

                        </NavLink>
                    ))}
                    <NavLink
                      to="/profile"
                      className={"-m-3 p-3 flex items-start rounded-lg sun"}
                    >                      
                      <img src={profileIcon} style={{ height: "35px", width: "35px" }} />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">Profile</p>
                        <p className="mt-1 text-sm text-gray-500">My Profiles</p>
                      </div>

                    </NavLink>
                    <NavLink
                      to="/Queue"
                      className={"-m-3 p-3 flex items-start rounded-lg earth"}
                    >                      
                      <img src={queue} style={{ height: "35px", width: "35px" }} />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">Client Queue</p>
                        <p className="mt-1 text-sm text-gray-500">List Client Queue</p>
                      </div>

                    </NavLink>
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6 hidden">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  {/* <a href="/Pricing" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Pricing
                  </a>

                  <NavLink to="/Profile" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Profile
                  </NavLink>
                  <NavLink to="/Queue" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Client Queue
                  </NavLink> */}
                  {/* {resources.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      {item.name}
                    </a>
                  ))} */}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <Modal_login Isopen={open} Contents={<Login />} Title="Login Constellation's">
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          Cancel
        </button></Modal_login>
    </>
  )
}
