import Queue from './Queue';
import Header from '../../Header';

function QueueRoutes() {
    return(
<>
<Header />
<Queue />
</>
    )
}
export default QueueRoutes;