import React, { useEffect, useState } from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { useSelector, useDispatch } from 'react-redux';
import * as vtuber_act from '../../../../../Middleware/action/vtuber.action';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Reviz from "../../../../Img/Vtube/Model_1.png"
import Golden from "../../../../Img/Vtube/Model_3.png"
import Taffeite from "../../../../Img/Vtube/Model_16.png"
import Hiroki from "../../../../Img/Vtube/model_26-removebg-preview.png"
import Ravias from "../../../../Img/Vtube/Model_2.png"
import $ from "jquery";

function Vtuber() {
  const dispatch = useDispatch();
  const vtuber = useSelector((state) => (state.vtuber));
  const [Hide, setHide] = useState(false);
  useEffect(() => {
    dispatch(vtuber_act.GetAll())
  }, [])
  useEffect(() => {
    if (vtuber.data.length !== 0) {
      setHide(true)
    }
  }, [vtuber.data])


  return (
    <>
      <div style={{ opacity: 1, backgroundColor: "white" }} className="relative  overflow-hidden mt-10  p-10 animate-fade-right">
        <div className="flex flex-col ...">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 5 }}
          // columnsCount={3} gutter="10px"
          >

            <Masonry className={Hide === false
              ? ""
              : "fade-out"}>
              <h1 className='animate-pulse'>LOADING...</h1>
              <img
                src={Reviz}
                style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
                alt=""
                className='animate-pulse '
              />
              <img
                src={Taffeite}
                style={{ width: "100%", height: "100%", display: "block", filter: "brightness(0%)" }}
                alt=""
                className='animate-pulse '
              />
              <img
                src={Golden}
                style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
                alt=""
                className='animate-pulse '
              />

              <img
                src={Hiroki}
                style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
                alt=""
                className='animate-pulse '
              />
              <img
                src={Ravias}
                style={{ width: "100%", height: "100%", display: "block", filter: "brightness(0%)" }}
                alt=""
                className='animate-pulse '
              />
            </Masonry>
            <Masonry>

              {vtuber.data.map((image, i) => (
                <img
                  key={i}
                  src={image.path}
                  style={{ width: "100%", display: "block" }}
                  alt=""
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    </>

  )
}

export default Vtuber;