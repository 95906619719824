import Api from "./api";


export default class Arts extends Api{
      getResult(){
        return this.result;
    }


    async GetALL(type){
      let postUrl = type;
      let result = await this.getAll(postUrl);
      this.result = result;
      return result;
    }


  // async createToken(jsonParams ={},type ="create",publickey){
  //   let postUrl = STRIPE_TOKEN_URL;
  //   if(type =='put'){
  //     let result = await this.putApi(postUrl,jsonParams);
  //     this.result = result;
  //     return result;
  //   }else{
  //     let result = await this.postApiStripe(postUrl,jsonParams,publickey);
  //     this.result = result;
  //     return result;
  //   }
  // }


}
