import { FindAll } from '../services/arts.service';
import * as type from '../type';
import *  as constant from '../constant';


export function GetAll() {
  return dispatch => {
    FindAll(constant.GETQUEUE).then(data => {
      dispatch({
        type: type.LIST_QUEUE,
        data: data
      });
    });
  }
}


// export async function CreateTokenAction(paramCard, publickey) {
//   let result = await CreateToken(paramCard, publickey);
//   return result;
// }


