import Vtuber from "./Vtuber";
import Header from "../../../../Header";
import FlyingUpload from "./Flying_Upload";
function VtuberRoutes() {
    return(
    <>
    <Header />
    <Vtuber  />
    {/* <FlyingUpload /> */}
    </>
    )
}

export default VtuberRoutes;