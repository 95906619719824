/* This example requires Tailwind CSS v2.0+ */
import golden from "./../../../../Img/Arts/Commish/Banner and Logo Commission/Golden Banner.png"
import zion from "./../../../../Img/Arts/Commish/Banner and Logo Commission/zion.png"
import kenna from "./../../../../Img/Arts/Commish/Banner and Logo Commission/kenna.png"
import { useSelector, useDispatch } from 'react-redux';
import * as animation_act from '../../../../../Middleware/action/animation.action';
import React, { useEffect, useState } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
function Assets() {

  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(true);
  const logoBanner = useSelector((state) => (state.animation.data));
  useEffect(() => {
    dispatch(animation_act.GetAll())
  }, [])
  useEffect(() => {
    if (logoBanner.length > 0) {
      setTimeout(() => {
        setLoading(false)
      }, 1000);
    }
  }, [logoBanner])
  return (
    <div className="relative bg-white overflow-hidden mt-10  p-10 animate-fade-right">
      <div className="flex flex-col ...">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1 style={{ fontSize: "35px" }}>ASSETS</h1>
        </div>
        {Loading
          ? <><h1 className='animate-pulse'>LOADING...</h1><br></br></>
          : <ResponsiveMasonry
            // columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            columnsCount={3} gutter="10px"
          >
            {/* <Masonry className={Hide === false
          ? ""
          : "fade-out"}>
          <img
            src={golden}
            style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
            className='animate-pulse '
            alt=""
          />
          <img
            src={zion}
            style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
            className='animate-pulse '
            alt=""
          />
          <img
            src={kenna}
            style={{ width: "100%", display: "block", filter: "brightness(0%)" }}
            className='animate-pulse '
            alt=""
          />
        </Masonry> */}
            <Masonry>
              {logoBanner.map((image, i) => (
                <img
                  key={i}
                  src={image.path}
                  style={{ width: "100%", display: "block" }}
                  alt=""
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        }

      </div>
    </div>
  )
}
export default Assets;