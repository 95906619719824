import Header from "../../../../Header";
import Overlay from "./Overlay"
function OverlayRoutes() {
    return (
        <>
            <Header />
            <Overlay />

        </>
    )
}
export default OverlayRoutes;