import Carousel from 'react-gallery-carousel';
import { useSelector, useDispatch } from 'react-redux';
import * as pricing_act from '../../../Middleware/action/pricing.action';
import React, { useEffect, useState } from 'react';

import Vtuberz from "./../../Img/Pricing/VTUBE PRICELIST.png"
import Vtuberz_process from "./../../Img/Pricing/Constellatia Vtube Process.png"
import Vtuberz_rule from "./../../Img/Pricing/RULES Resize.png"
import Vtuberz_rules from "./../../Img/Pricing/Constellatia Vtube Rules.png"
import Vtuberz_rules1 from "./../../Img/Pricing/Vtube Commission Rules.png"
import BannerLogo from "./../../Img/Pricing/Constellatia Banner Pricelist.png"
import Arts_only from "./../../Img/Pricing/Constellatia Art Commission Pricelist.png"
import Assets from "./../../Img/Pricing/Constellatia's Assets Pricelist.png"
import overlay from "./../../Img/Pricing/Stream Layout Commission Pricelist.png"

// import Logo1 from "./../../Img/Pricing/LOGO COMMISSIONS BY CONSTELLATIA FIRST VER.png"
import Logo2 from "./../../Img/Pricing/LOGO COMMISSIONS BY CONSTELLATIA.png"

// import BannerLogo from "./../../Img/Golden Banner and Logo Template.png"
// import Arts_only from "./../../Img/Constellatia Pricelist Template.png"
// import Logo from "./../../Img/LOGO COMMISSIONS BY CONSTELLATIA.png"
function Rules() {
  const vt = [Vtuberz].map((number) => ({
    src: `${number}`
  }));
  const vtrul = [Vtuberz_rule].map((number) => ({
    src: `${number}`
  }));
  const vtrule = [Vtuberz_rules].map((number) => ({
    src: `${number}`
  }));
  const vtrule1 = [Vtuberz_rules1].map((number) => ({
    src: `${number}`
  }));
  const vtprocess = [Vtuberz_process].map((number) => ({
    src: `${number}`
  }));
  // const lg1 = [Logo1].map((number) => ({
  //   src: `${number}`
  // }));    
  const lg2 = [Logo2].map((number) => ({
    src: `${number}`
  }));

  const as = [Assets].map((number) => ({
    src: `${number}`,
  }));

  const bn = [BannerLogo].map((number) => ({
    src: `${number}`
  }));
  const ov = [overlay].map((number) => ({
    src: `${number}`
  }));
  const ar = [Arts_only].map((number) => ({
    src: `${number}`
  }));

  const dispatch = useDispatch();
  const [Hide, setHide] = useState(false);
  // const pricing = useSelector((state) => (state.pricing.data));
  // useEffect(() => {
  //   dispatch(pricing_act.GetAll())
  // }, [])
  // useEffect(() => {
  //   if (pricing.length > 0) {
  //     setHide(true)
  //   }
  //   console.log("overlay",overlay)
  // }, [pricing])
  return (
    <div className="relative bg-white overflow-hidden mt-10  p-10 ">
      <div className="flex flex-col ...">
        <div className="text_banner text-center mt-5 
        animate-flip-up animate-once animate-ease-linear animate-normal animate-fill-forwards"> Rules </div>

        <div className="text_banner  mt-5 animate-fade-right"> Vtube Commission Rules </div>
        <div>
          <Carousel
            className='framed-carousel animate-fade-right'
            images={vtrul}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasThumbnails={false}
            shouldMaximizeOnClick={true}
            shouldMinimizeOnClick={true}
          />

          <Carousel
            className='framed-carousel mt-5 animate-fade-left'
            images={vtrule1}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasThumbnails={false}
            shouldMaximizeOnClick={true}
            shouldMinimizeOnClick={true}
          />

          <Carousel
            className='framed-carousel mt-5 animate-fade-right'
            images={vtrule}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasThumbnails={false}
            shouldMaximizeOnClick={true}
            shouldMinimizeOnClick={true}
          />
        </div>

        <div className="text_banner  mt-5 animate-fade-left"> Vtube Commission Process </div>
        <div>
          <Carousel
            className='framed-carousel animate-fade-left'
            images={vtprocess}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasThumbnails={false}
            shouldMaximizeOnClick={true}
            shouldMinimizeOnClick={true}
          />
        </div>

      </div>
    </div>
  )
}

export default Rules