import { type } from "@testing-library/user-event/dist/type";
import * as types from '../type';

const initialState = {
  data: [],
  stripe: "",
  loading: true
};

const artsReducers = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_PERSONALARTS:
      return {
        ...state,
        loading: false,
        data: action.data
      };
      case types.LIST_COMMISIONARTS:
        return {
          ...state,
          loading: false,
          data: action.data
        };
    default:
      return state;
  }
}

export default artsReducers;
