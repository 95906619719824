import animationAPI from '../animation';

export const FindAll = async (constant) => {
  try {

    let arts = new animationAPI();
    let getarts = await arts.GetALL(constant);
    let artsData = getarts["data"];

    if (artsData) {
      return artsData;
    }

    return null;
  } catch (e) {
    return null;
  }
}



// export const CreateToken = async (paramCard, publickey) => {
//   try {

//     let membership = new membershipApi();
//     let getMembership = await membership.createToken(paramCard, "create", publickey);
//     let membershipData = getMembership;

//     if (membershipData) {
//       return membershipData;
//     }

//     return null;
//   } catch (e) {
//     return null;
//   }
// }

