// import { createStore, applyMiddleware } from 'redux';
// import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
// import rootReducer from '../_reducers';
//
// const loggerMiddleware = createLogger();
//
// export const store = createStore(
//   rootReducer,
//   applyMiddleware(
//     thunkMiddleware,
//     loggerMiddleware
//   )
// );

import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import vtuberReducers from "./reducers/vtuber.reducers";
import artsReducers from "./reducers/arts.reducers";
import logoBannerReducers from './reducers/logobanner.reducers';
import eventReducers from './reducers/event.reducers';
import queueReducers from './reducers/queue.reducers';
import animationReducers from './reducers/animation.reducers';
import overlayReducers from './reducers/overlay.reducers';
import pricingReducers from './reducers/pricing.reducers';

export default configureStore({
    reducer: {
        // token: tokenReducers,
        vtuber: vtuberReducers,
        arts: artsReducers,
        logobanner: logoBannerReducers,
        event: eventReducers,
        queue: queueReducers,
        animation: animationReducers, 
        overlay: overlayReducers, 
        pricing: pricingReducers,
    },
});
